<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'facturacion_servicios',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title + (loading ? '' : ' (' + tbl_data.length + ')')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-select
            label="Estado"
            v-model="tbl_filter"
            :items="tbl_filters"
            item-value="id"
            :item-text="(v) => v.value"
            dense
            :disabled="loading"
            @change="getTableData"
          />
        </v-col>
        <v-col v-if="tbl_data.length > 0" cols="12" xs="12" md="12">
          <v-text-field
            v-model="tbl_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_headers"
            :search="tbl_search"
            :items="tbl_data"
            :loading="loading"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.key="{ item }">
              <b v-text="item.key" />
            </template>
            <template v-slot:item.action="{ item }">
              <div class="text-right">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-on="on"
                      :color="
                        (item.response ? 'green' : 'orange') + ' darken-1'
                      "
                      @click="dataDialog(item)"
                      class="ml-1"
                    >
                      <v-icon>
                        mdi-comment-{{
                          item.discharged
                            ? "check"
                            : !item.response
                            ? "arrow-right"
                            : "arrow-left"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Detalle'" />
                </v-tooltip>
                <v-tooltip v-if="item.response && !item.discharged" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-on="on"
                      color="warning"
                      @click="doctorCommentDischarged(item)"
                      class="ml-1"
                    >
                      <v-icon> mdi-comment-check </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Terminar'" />
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="data_dialog" scrollable persistent max-width="900">
      <v-card
        tile
        :disabled="data_dialog_loading"
        :loading="data_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title>
            PREGUNTA O COMENTARIO{{ data ? " | ID " + data.id : "" }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="data_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data">
          <v-row dense>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" />
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Correo electrónico" :value="data.email" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Correo electrónico alterno"
                    :value="data.email2"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Teléfono" :value="data.phone" />
                </v-col>

                <v-col cols="12">
                  <v-card tile>
                    <v-toolbar elevation="1" dense>
                      <v-toolbar-title>
                        <span class="text-caption" v-text="'DOCUMENTOS'" />
                      </v-toolbar-title>
                      <v-spacer />
                      <v-toolbar-items />
                    </v-toolbar>
                    <v-spacer />
                    <v-card-text>
                      <v-row dense>
                        <v-col
                          cols="12"
                          v-for="(document, i) in data.doctor_comment_documents"
                          :key="i"
                        >
                          <v-row dense>
                            <v-col cols="11">
                              <ViewData
                                :label="`${i + 1}. Descripción`"
                                :value="document.description"
                              />
                            </v-col>
                            <v-col cols="1">
                              <v-btn
                                fab
                                x-small
                                class="ml-1"
                                color="warning"
                                :href="
                                  url_documents +
                                  '/doctor_comment/' +
                                  document.url
                                "
                                target="_blank"
                              >
                                <v-icon> mdi-download </v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12">
                              <v-divider class="pb-1" />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card tile>
                    <v-toolbar elevation="1" dense>
                      <v-toolbar-title>
                        <span class="text-caption" v-text="'HISTORIAL'" />
                      </v-toolbar-title>
                      <v-spacer />
                      <v-toolbar-items />
                    </v-toolbar>
                    <v-spacer />
                    <v-card-text>
                      <v-row dense>
                        <v-col
                          cols="12"
                          v-for="(
                            doctor_comment_detail, i
                          ) in data.doctor_comment_details"
                          :key="i"
                        >
                          <v-row dense>
                            <v-col cols="11">
                              <span
                                class="text-caption font-weight-bold"
                                v-text="
                                  doctor_comment_detail.created_by.email +
                                  ' | ' +
                                  doctor_comment_detail.created_at
                                "
                              />
                              <v-icon
                                small
                                :color="
                                  doctor_comment_detail.response
                                    ? 'info'
                                    : 'warning'
                                "
                                right
                              >
                                mdi-email-arrow-{{
                                  doctor_comment_detail.response
                                    ? "left"
                                    : "right"
                                }}
                              </v-icon>
                              <br />
                              <span
                                class="text-description"
                                v-text="doctor_comment_detail.detail"
                              />
                            </v-col>
                            <v-col cols="1">
                              <v-btn
                                v-if="doctor_comment_detail.document_url"
                                fab
                                x-small
                                class="ml-1"
                                color="warning"
                                :href="
                                  url_documents +
                                  '/doctor_comment/' +
                                  doctor_comment_detail.document_url
                                "
                                target="_blank"
                              >
                                <v-icon> mdi-download </v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12">
                              <v-divider class="pb-1" />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col v-if="!data.response" cols="12">
                  <v-card tile>
                    <v-toolbar elevation="1" dense>
                      <v-toolbar-title>
                        <span class="text-caption" v-text="'RESPUESTA'" />
                      </v-toolbar-title>
                      <v-spacer />
                      <v-toolbar-items />
                    </v-toolbar>
                    <v-spacer />
                    <v-card-text>
                      <v-form
                        v-on:submit.prevent
                        ref="doctor_comment_detail_form"
                        lazy-validation
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <v-textarea
                              label="Comentario*"
                              rows="3"
                              v-model="doctor_comment_detail.detail"
                              dense
                              :rules="rules.required"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-file-input
                              label="Archivo digital (PDF)"
                              v-model="doctor_comment_detail.document_file_0"
                              accept="image/,.pdf"
                              :rules="rules.fileLmt"
                              show-size
                              prepend-icon=""
                              append-icon="$file"
                              dense
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-btn
                              block
                              color="info"
                              @click.prevent="doctorCommentDetailSubmit"
                              small
                            >
                              Enviar
                              <v-icon right> mdi-email-arrow-left </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgAlert,
  msgConfirm,
  toFormData,
  URL_DOCUMENTS,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      rules: rules(),
      loading: true,
      tbl_data: [],
      tbl_search: "",
      tbl_headers: [
        { text: "#", filterable: false, value: "key", width: "80" },
        { text: "ID", filterable: true, value: "id", width: "80" },
        { text: "IDM", filterable: true, value: "doctor_id" },
        { text: "Usuario", filterable: true, value: "created_by.email" },
        { text: "Nombre", filterable: true, value: "doctor_full_name" },
        { text: "Creado", filterable: true, value: "created_at" },
        { text: "Terminado", filterable: true, value: "discharged" },
        {
          text: "",
          fixed: true,
          value: "action",
          sortable: false,
          width: "110",
        },
      ],
      tbl_filter: 0,
      tbl_filters: [
        { id: 0, value: "PENDIENTES" },
        { id: 1, value: "TERMINADOS" },
        { id: 2, value: "TODOS" },
      ],
      data: null,
      data_dialog: false,
      data_dialog_loading: false,
      doctor_comment_detail: null,
      key: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getTableData() {
      this.loading = true;
      this.tbl_data = [];

      Axios.get(
        URL_API + "/doctors/comments?filter=" + this.tbl_filter,
        headersToken(this.login.token)
      ).then((response) => {
        this.tbl_data = response.data.data;
        this.loading = false;
      });
    },
    dataDialog(item) {
      this.key = item.key;

      this.doctor_comment_detail = {
        id: null,
        active: true,
        response: true,
        doctor_comment_id: item.id,
        detail: "",
        document_file_0: null,
      };
      this.data = null;
      this.data_dialog_loading = true;
      this.data_dialog = true;

      Axios.get(
        URL_API + "/doctors/comments/" + item.id,
        headersToken(this.login.token)
      ).then((response) => {
        this.data = response.data.data;
        this.data_dialog_loading = false;
      });
    },
    doctorCommentDetailSubmit() {
      if (this.$refs.doctor_comment_detail_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma enviar la respuesta?"))
          .then((res) => {
            if (res.isConfirmed) {
              this.data_dialog_loading = true;

              Axios.post(
                `${URL_API}/doctors/comments/detail`,
                toFormData(this.doctor_comment_detail),
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.tbl_data[this.key - 1].response = true;
                  this.data_dialog = false;
                } else {
                  console.log(resp.data.message);
                  this.data_dialog_loading = false;
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    doctorCommentDischarged(item) {
      this.$swal
        .fire(msgConfirm("¿Confirma terminar la conversación?"))
        .then((res) => {
          if (res.isConfirmed) {
            this.key = item.key;
            this.loading = true;

            Axios.post(
              URL_API + "/doctors/comments/discharged",
              {
                id: item.id,
              },
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.tbl_data[this.key - 1].discharged = res.data.discharged;
                if (this.tbl_filter == 0) {
                  this.tbl_data.splice(this.key - 1, 1);
                }
              } else {
                console.log(res.data.message);
              }

              this.loading = false;
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getTableData();
  },
};
</script>